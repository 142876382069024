<template>
    <Layout>
        <b-card v-if="!load">
            <FilterBase session="Leads" :filter="filter"/>

            <TableList session="Leads" url="mentorias/" :table="table" :keys="keys" ref="tableData">

                <template #img="{ value }">
                    <router-link :to="`/mentorias/detalhes/${value.mentoring.id}/mentorando/${value.mentee.id}`">
                        <div class="avatar avatar-sm mr-8pt">
                            <img class="avatar-title rounded-circle"
                                 :src="value.mentee.photo_url" alt="">
                        </div>
                    </router-link>
                </template>

                <template #name="{ value }">
                    <router-link :to="`/mentorias/detalhes/${value.mentoring.id}/mentorando/${value.mentee.id}`">
                        <div class="media flex-nowrap align-items-center"
                             style="white-space: nowrap;">
                            <div class="media-body">

                                <div class="d-flex flex-column">
                                    <p class="mb-0"><strong
                                        class="js-lists-values-name"> {{
                                            value.mentee.name
                                        }}</strong></p>
                                    <small
                                        class="js-lists-values-email text-50">{{
                                            value.mentee.email
                                        }}</small>
                                </div>

                            </div>
                        </div>
                    </router-link>
                </template>

                <template #title="{ value }">
                    <span>{{ value.mentoring.title }}</span>
                </template>

                <template #created_at="{ value }">
                    <span>{{ convertDateText(value.created_at) }}</span>
                </template>

                <template #price="{ value }">
                    <span>{{ maskMoneyTable(value.price) }}</span>
                </template>

                <template #progress="{ value }">
                    <span>{{ value.progress.replace('.', ',') }} %</span>
                </template>

            </TableList>

        </b-card>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue'
import TableList from "@/components/base/table-list.vue";
import FilterBase from '@/components/base/filter-component.vue';
import {setSessions} from "@/components/composables/setSessions";
import {filter} from './js/filter'
import {table, keys} from "./js/table";
import {convertDateText, maskMoneyTable} from "@/components/composables/masks";
import http from '@/http'

export default {

    components: {
        Layout,
        FilterBase,
        TableList,
    },

    data() {
        return {
            load: false,
            types: ['edit', 'details', 'copy'],
            filter,
            table,
            keys,
        }
    },

    methods: {
        maskMoneyTable,
        convertDateText,
        getMentorings() {
            http.get('mentorings?paginated=false&orderByDesc=title&status=1')
                .then(response => {
                    response.data.forEach(el => {
                        this.filter.selects[0].options.push({
                            value: el.id,
                            name: el.title
                        })
                    })
                })
        }
    },

    mounted() {
        if (!localStorage.getItem('Leads')) setSessions('Leads');
        this.getMentorings();
    }
}
</script>
